import * as PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby";

import { QuestionAnswer, TokenName } from "../components/index";
import { tokenTypeType } from "../types";

class FaqMostPopularTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    const { question, route } = props.pageContext.faq;
    this.backBreadcrumPages = [
      {
        text: 'FAQ',
        to: '/faq/'
      },
      {
        text: question,
        to: route
      }
    ];
  }
  getTokens() {
    const {
      pageContext: {
        tokens
      }
    } = this.props;
    return (
      <ol>
      {
        tokens.map((token, index)=>{
          const { 
            name, color,
            power, toughness,
            types, id
          } = token.node;
          return (
            <li key={index}>
              <Link
                to={`/token-list/${id}/`}
                state={{
                  breadcrum: this.backBreadcrumPages
                }}
              >
                <TokenName
                  shortName={true} power={power} toughness={toughness} 
                  color={color} types={types} name={name}
                />
              </Link>
            </li>
          )
        })
      }
      </ol>
    )
  }
  render() {
    const {
      pageContext: {
        faq,
      }
    } = this.props;
    const intro = "The most popular Magic: the Gathering tokens are:";
    const outText = "Popularity is measured by the number of times that the token has been printed. These are tokens that are consistently created by MTG cards and will have been regularly played in the rotating formats."
    const htmlAnswer = `${intro}<br>${this.getTokens()}<br>${outText}`;
    return (
      <QuestionAnswer {...faq} answer={htmlAnswer}>
        <>
          <p>{intro}</p>
          {this.getTokens()}
          <p>{outText}</p>
          <p>Read more on about these tokens on our <Link to="/most-common-mtg-tokens/">blog post</Link>.</p>
        </>
      </QuestionAnswer>
    )
  }
}

FaqMostPopularTemplate.propTypes = {
  pageContext: PropTypes.shape({
    faq: PropTypes.shape({
      route: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      formattedDate: PropTypes.string.isRequired,
    }),
    tokens: PropTypes.arrayOf(PropTypes.shape({
      node: PropTypes.shape({
        ...tokenTypeType
      }).isRequired,
    })).isRequired,
  }).isRequired
}

export default FaqMostPopularTemplate